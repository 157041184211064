<template>
    <main v-if="company" id="js-page-content" class="page-content" role="main">
        <div class="subheader hidden-lg-up">
            <portal to="ariane">
                <MultipleListCreate></MultipleListCreate>
            </portal>
        </div>

        <div
            class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3"
        >
            <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                <!-- Information generales -->
                <section aria-labelledby="applicant-information-title">
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h2
                                id="applicant-information-generales"
                                class="text-lg leading-6 font-medium text-blue-500"
                            >
                                {{ $t('post.page.information.general') }}
                            </h2>
                        </div>
                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl
                                class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2"
                            >
                                <div class="sm:col-span-1">
                                    <dt
                                        class="text-sm font-medium text-gray-500"
                                    >
                                        {{
                                            $t(
                                                'post.page.information.reference'
                                            )
                                        }}
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        #{{ company.id }}
                                    </dd>
                                </div>
                                <div class="sm:col-span-1">
                                    <dt
                                        class="text-sm font-medium text-gray-500"
                                    >
                                        {{ $t('compagnies.name') }}
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{ company.name }}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MultipleListCreate from '@/components/UI/MultipleListCreate'

export default {
    name: 'Company',
    components: {
        MultipleListCreate
    },
    data: function () {
        return {
            loading: true
        }
    },
    created() {
        var id = this.$route.params.id
        this.getCompany(id).then(() => {
            this.loading = false
        })
    },
    computed: {
        ...mapState('admin', {
            company: (state) => state.company
        })
    },
    methods: {
        ...mapActions('admin', ['getCompany'])
    }
}
</script>
